/* ensure all pages have Bootstrap CSS */
@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", Roboto Condensed, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", "Open Sans", sans-sarif;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 11px;
}

p {
  font-size: 14px
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --bs-body-font-family: Open Sans;
  --toastify-color-error: #ef5350 !important;
  --toastify-color-success: #62b85c !important;
  --toastify-font-family: Open Sans !important;
}

.amplify-button[data-variation="link"]:focus {
  box-shadow: none !important;
}

.amplify-button {
  display: none !important;
  border-left: none !important;
}

.amplify-field-group__outer-end .amplify-field-group__control {
  display: block !important;
}

.amplify-input {
  height: 48px !important;
}

/* .amplify-heading {
  padding: 53px 0 4px !important;
}
 */
.amplify-button amplify-field-group__control {
  padding-bottom: 45px;
}

.amplify-label {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.navbar-padding {
  padding-top: 32px;
  padding-left: 32px;
}

[data-amplify-router] {
  /* background-color:  map-get($theme-colors, "light") !important; */
  background-color: #fafafa !important;
  border: none !important;
  border-radius: 11px !important;
  box-shadow: 0 9px 14px 0 rgba(0, 0, 0, 0.2);
}

[data-amplify-authenticator] {
  --amplify-colors-neutral-60: #9e9e9e;
  --amplify-colors-neutral-90: #262e47;
  --amplify-components-fieldcontrol-border-color: #9e9e9e;
  --amplify-components-fieldcontrol-focus-border-color: #3665df;
  --amplify-components-button-hover-background-color: #ffff;
  --amplify-components-button-hover-border-color: #9e9e9e;

  --amplify-components-radio-button-checked-color: #3a60d8;
  --amplify-components-button-link-hover-background-color: #ffff;
  --amplify-components-button-link-active-background-color: #ffff;
  --amplify-components-button-link-focus-background-color: #ffff;
  --amplify-components-button-link-hover-color: #3a60d8;
  --amplify-components-button-link-color: #3a60d8;
  --amplify-components-button-link-active-color: #3a60d8;
  --amplify-components-button-link-focus-color: #3a60d8;
  --amplify-components-button-focus-box-shadow: #ffff;
  --amplify-components-heading-3-font-weight: 700;

  --amplify-components-text-color: #333333;
  --amplify-components-button-active-background-color: #ffff;
  --amplify-components-button-active-border-color: #3665df;
}
