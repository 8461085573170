@import '../styles/globals.scss';

.navbar {
  width: 100%;
}

.title {
  color: map-get($map: $colors, $key: "dark-blue");
  margin-bottom: 0;
  font-weight: 700;
}
