@import "../styles/globals.scss";

.row {
  width: 100%;
  height: 56px;
  padding: 19px 0 18px 0;
  border: solid 1px map-get($custom-colors, "gray-border") !important;
  background-color: white;
}

.border-y-zero {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.expanded-color {
  background-color: #f6f7ff !important;
}

.cell {
  text-align: left;
  padding-left: 16px;
  padding-right: 8px;
  height: 19px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
}

.table-title-container {
  width: 100%;
  min-height: 61px;
  padding: 12px 12px 12px 16px;
  border-radius: 7px 7px 0 0;
  border: solid 1px map-get($custom-colors, "gray-border");
  background-color: white;

}

.column-title-container {
  height: 40px;
  padding: 12px 56px 11px 32px;
  background-color: map-get($custom-colors, "light-gray");
}

.column-title {
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: 16px;
  color: #444;
  border: 0;
  width: 200px;
  max-width: 200px;
  min-width: 150px;
}

.icon {
  max-width: 8px;
  max-height: 16px;
  margin: 1px 7px 0 0;
  font-family: Ionicons;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: map-get($custom-colors, "regular-gray");
}

.actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border-left: solid 1px map-get($custom-colors, "gray-border") !important;
  border-right: solid 1px map-get($custom-colors, "gray-border") !important;
  padding-left: 12px;
  float: right;
  position: absolute;
  right: 0;
  background-color: white;
}

.action {
  font-size: 12px;
  // margin-right: 12px;
  padding: 5.5px 8px;
  border-radius: 50%;
  border: solid 1px map-get($custom-colors, "gray-border") !important;
  background-color: white;
  &:hover {
      background-color: $accent-color;
      color: white;
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 11px 6px 0 0;
  background-color: map-get($custom-colors, "light-gray");
  position: absolute;
  bottom: -3rem;
  right:  0;
}

.pagination-option {
  height: 36px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: map-get($custom-colors, "dark-gray");
  border-radius: 3px;
  border: solid 1px map-get($custom-colors, "gray-border") !important;
  background-color: white;
}

.page-option {
  padding: 7px 12px;
  border: solid 1px map-get($custom-colors, "gray-border") !important;
  background-color: white;
  height: 36px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.selected-option {
  font-weight: bold;
  color: $accent-color;
}

.icon-disabled {
  cursor: none;
  opacity: 0.5;
}

.align-center {
  align-items: center;
}

.sorted-title {
  color: $primary-blue;
}
