@import './globals.scss';

.navbar-padding {
  padding-top: 32px;
  padding-left: 32px;
}

.navbar-brand{
  &:hover {
    cursor: pointer;
  }
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-container {
    margin-top: 33px;
}

.title {
  color: white;
  margin-bottom: 4px;
}

.message {
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 0;
  color: map-get($map: $colors, $key: "white" );
}

.button {
  width: 256px;
  margin-top: 64px;
} 
