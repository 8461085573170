// Reference: https://getbootstrap.com/docs/5.0/customize/sass/
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";
// 2. Include any default variable overrides here
@import "buttons";
@import "typography";
@import "_nav";
@import "_dropdown";
@import "_breadcrumb";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "variables";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/breadcrumb";

// 5. Add additional custom code here
.amplify-authenticator__subtitle {
  display: none !important;
}
.big-text-bold {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
}

.big-text {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
}

.breadcrumb {
  margin-bottom: 0rem;
  font-family: Roboto Condensed;
  font-size: 24px;
  color: map-get($colors, "dark-gray") !important;
}

.breadcrumb-item.active {
  font-weight: bold;
  color: map-get($colors, "dark-gray");
}

.btn {
  min-width: 130px;
  width: 160px;
}

.btn-primary {
  color: map-get($colors, "white") !important;
  border: solid 1px map-get($theme-colors, "primary");
}

.btn-secondary {
  background-color: map-get($theme-colors, "white");
  border: solid 1.5px map-get($theme-colors, "primary");
  color: map-get($theme-colors, "primary");
  &:hover {
    color: $white;
    background-color: $accent-color;
    border-color: map-get($theme-colors, "primary");
  }
  &:focus {
    color: $white !important;
    background-color: $accent-color !important;
    border-color: map-get($theme-colors, "primary") !important;
  }
}

.btn-link {
  color: map-get($colors, "link-blue");
  width: auto;
  font-size: 14px;
  font-weight: 600 !important;
}

.btn-outline-primary:hover {
  color: map-get($theme-colors, "white");
}

.bg-dark {
  background-color: map-get($theme-colors, "dark") !important;
}

.bg-success {
  background-color: map-get($theme-colors, "success") !important;
}

.btn-danger {
  color: white;
}

.btn-light {
  border-color: map-get($colors, "gray-border") !important;
}

.bg-light {
  background-color: map-get($theme-colors, "light") !important;
}

.bg-gray {
  background-color: map-get($theme-colors, "gray") !important;
}

.bg-disabled {
  background-color: map-get($theme-colors, "disabled-gray") !important;
}

.text-success {
  color: map-get($theme-colors, "success") !important;
}

.text-muted {
  color:  map-get($theme-colors,"muted") !important;
}

.amplify-heading {
  font-family: Roboto Condensed !important;
}

.form-label {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
  max-width: 480px;
}

.form-control {
  font-size: 14px;
  min-height: 40px;
  &:hover {
    border: solid 1px $primary-blue;
    box-shadow: none;
  }
  &:focus {
    border: solid 1px $primary-blue;
    box-shadow: none;
  }
}

.invalid-feedback {
  display: inline;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0;
}

.Toastify__toast {
  font-weight: 700;
  border-radius: 6px !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.3) !important;
}

.Toastify__close-button {
  opacity: 1 !important;
}
.Toastify__toast-icon {
  width: 16px !important;
}

.form-check-input{
  height: 14px;
  width: 14px;
}

.form-check-input[type="checkbox"]:indeterminate,
.form-check-input:checked {
  background-color: #244bc4;
}

.navbar-toggler {
  color: white !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.25rem 0;
}

.offcanvas-header {
  padding: 0;
  height: 48px;
}

.offcanvas-header .btn-close {
  position: absolute;
  left: 8px;
}

.modal-content {
  max-height: 90vh;
  border-radius: 11px;
  border: none;
}

.modal-dialog {
  max-width: 530px;
}

.modal-sm {
  max-width: 400px !important;
}

.modal-location-picker {
  max-width: 440px;
  .modal-content{
    overflow: unset;
  }
}

.modal-header {
  padding: 26px 32px;
}

.modal-body {
  padding: 26px 32px;
  overflow: auto;
}

.border-primary {
  border-color: $primary-blue !important;
}


.border-secondary {
  border-color: map-get($colors, "gray-border") !important;
}

.form-title {
  font-family: "Roboto Condensed";
  font-weight: 600;
  margin: "0 0 32px 0";
}