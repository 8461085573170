@import "./globals.scss";

.custom-form-control {
  border-end-end-radius: 0px;
  border-start-end-radius: 0px;
}

.eye-container {
  border-end-end-radius: 3px;
  border-start-end-radius: 3px;
}

.number {
  padding: 0.375rem 0.75rem;
  border: solid 1px #cdcdcd;
  font-size: 14px;
  &:hover {
    border: solid 1px map-get($map: $colors, $key: "primary-blue");
    box-shadow: none;
  }
  &:focus {
    border: solid 1px map-get($map: $colors, $key: "primary-blue") !important;
    box-shadow: none !important;
  }
  &:focus-visible {
    outline:none!important;
    border: solid 1px map-get($map: $colors, $key: "primary-blue") !important;
    box-shadow: none !important;
  }
}

.optional {
  margin-left: 4px;
  font-size: 12px;
  color: #818a91;
  font-weight: 600;
}

.date-range-input {
  min-width: 115px;
  width: 115px;
  height: 35px;
  margin: auto;
  margin-top: 8px;
}
