@import "../styles/globals.scss";
.sidebar {
  max-width: 280px;
  width: 20%;
}

.fill-space {
  height: calc(100vh - 56px);
  width: 101%;
  background-color: map-get($custom-colors, "light-gray");
}

.main-container {
  border: 0;
  margin-bottom: 8;
  box-shadow: none;
  cursor: pointer;
  width: 240;
  transition: background-color 500ms linear;
}

.header {
  border: 0;
  box-shadow: none;
  background-color: transparent;
  height: fit-content;
  min-height: 40px;
  padding: 4px 0;
}

.module {
  margin-top: 15px;
  border: 0;
  box-shadow: none;
  padding: 4px 7px;
  word-break: break-word;
  text-align: start;
  width: 100%;
  border-radius: 4px;
  color: white;
}

.submodule {
  padding: 0.5rem 1rem;
  font-family: Open Sans;
  border-radius: 6px;
}

.submodule-container {
  background-color: transparent;
  padding: 1rem 1.5rem !important;
  color: white;
}

.version {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding-top: 16px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  opacity: 0.5;
}