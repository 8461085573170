.wrapper {
  position: relative;
  top: 0;
  min-height: 100vh;
  display: flex;
  flex: 1;
}

.content {
  flex-grow: 1!important;
  overflow: auto;
  background-color: #f6f6f6;
  max-height: 100vh;
  overflow: hidden;
}

.formContent {
  overflow: auto;
  height: calc(100vh - 48px); // nav-bar height 48px
  width: 100%;
  padding: 32px 32px 80px 32px;
  
  @media (min-width: 1200px) {
    width: calc(100vw - 280px);
  }
}
