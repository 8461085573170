$primary-blue: #3665df;
$primary-white: #fafafa;
$accent-color: #fe7c2c;

$custom-colors: (
  "custom-blue": #3a60d8,
  "link-blue": #0083d6,
  "primary-blue": #3665df,
  "custom-white": #fafafa,
  "accent-color": #fe7c2c,
  "dark-gray": #333333,
  "light-gray": #f6f6f6,
  "gray-border": #d0dde6,
  "dark-blue": #262e47,
  "gray-white": #e8e8e8,
  "custom-green": #62b85c,
  "success": #62b85c,
  "custom-red": #ef5350,
  "regular-gray": #9ba9b5,
  "accent-color-opacity": rgba(254, 124, 44, 0.4),
);

// Merge the maps
$colors: map-merge($colors, $custom-colors);

$theme-colors: (
  "primary": map-get($colors, "accent-color"),
  "dark": map-get($colors, "primary-blue"),
  "light": map-get($colors, "custom-white"),
  "gray": map-get($colors, "gray-border"),
  "danger": map-get($colors, "custom-red"),
  "success": map-get($colors, "custom-green"),
  "muted": map-get($colors, "regular-gray"),
  "disabled-gray": #e9ecef,
);
