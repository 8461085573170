@import "../styles/globals.scss";

.search-container {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  border: solid 1px map-get($custom-colors, "gray-border");
  &:hover {
    border-radius: 3px;
    border: solid 1px $primary-blue;
  }
  &:active {
    border-radius: 3px;
    border: solid 1px $primary-blue;
  }
}
.search-icon {
  background-color: white;
  border: 0 !important;
  padding: 0.375rem 0 0.375rem 0.75rem;
}

.search-box {
  padding: 0.375rem 0 0.375rem 0.75rem;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
  border: 0 !important;
}

.datepicker {
  :global {
    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonthAndYearWrapper {
      padding-top: 0;
      height: 40px;
    }
    .rdrWeekDay {
      color: map-get($custom-colors, "dark-gray");
    }
    .rdrMonthName {
      text-align: center;
      color: map-get($custom-colors, "dark-gray");
    }
    .rdrMonthAndYearPickers select:hover {
      background-color: white;
    }
    .rdrDefinedRangesWrapper {
      padding-top: 1rem;
      width: 155px;
      border-right: solid 1px map-get($custom-colors, "gray-border");
    }
    .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
      color: white !important;
      background-color: map-get($custom-colors, "primary-blue");
    }
    .rdrStartEdge {
      color: map-get($custom-colors, "primary-blue") !important;
    }
    .rdrInRange {
      color: map-get($custom-colors, "primary-blue") !important;
    }
    .rdrEndEdge {
      color: map-get($custom-colors, "primary-blue") !important;
    }
    .rdrDay {
      color: map-get($custom-colors, "primary-blue") !important;
    }
    .rdrDayHovered {
      color: map-get($custom-colors, "primary-blue") !important;
    }
    .rdrDayToday .rdrDayNumber span:after {
      background: map-get($custom-colors, "primary-blue") !important;
    }
    & span {
      font-weight: 600;
    }
  }
}

.toggle-button {
  height: 40px;
  min-width: 180px;
  max-width: 180px;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
}

.toggle-button-label {
  margin: 0;
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.toggle-input {
  height: 40px;
  width: 100%;
  border: 1px solid rgb(205, 205, 205);
  border-color: rgb(205, 205, 205) !important;
  box-shadow: none !important;
}

.toggle-input-title {
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 4px;
}

.optional {
  margin-left: 4px;
  font-size: 12px;
  color: #818a91;
  font-weight: 600;
}

.clear-button {
  background-color: #818a91;
  &:hover {
    color: "#fff";
  }
}

.start-date {
  position: absolute;
  top: -50px;
  left: 0px;
}

.end-date {
  position: absolute;
  top: -50px;
  left: 120px;
}
